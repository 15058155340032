import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"

const ComponentName = ({ data }) => {
  const { Info, Title, Description } = data.blog

  return (
    <Layout>
      <SEO Title={Title} Descriptionription={Description} />
      <section className="blog-template">
        <div className="section-center">
          <article className="blog-Info">
            <ReactMarkdown source={Info} />
          </article>
          <Link to="/blog" className="btn center-btn">
            blog
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($Slug: String) {
    blog: strapiBlogs(Slug: { eq: $Slug }) {
      Info
      Title
      Description
    }
  }
`

export default ComponentName
